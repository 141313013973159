import Dropzone from "dropzone";
import { Controller } from "stimulus";
import { DirectUpload } from "@rails/activestorage";
import {
  getMetaValue,
  toArray,
  findElement,
  removeElement,
  insertAfter
} from "helpers";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.inputTarget.dataset.directUploadUrl = this.inputTarget.dataset.directUploadUrl + '?file_attachment=true';
    this.dropZone = createDropZone(this);
     if (this.hasInputTarget && this.inputTarget.dataset.disableFileInput === 'true') {
       this.dropZone.disable();
     }
     this.hideFileInput();
     this.bindEvents();
     Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console

    // When a user tries to upload a video or audio file via file message type and that file exceed the maxFileSize we display the following error message
    this.dropZone.dictFileTooBig = () => {
      return (file) => { // Return a function that accepts the file
        if (file && (file.type.startsWith('audio/') || file.type.startsWith('video/'))) {
          return "Looks like you're trying to upload a media file as an attachment.  Use the media player upload option instead.";
        }
        return null; // Return null for default handling
      };
    };

    this.dropZone.on('error', (file, message) => {
      if (message.includes("File is too big")) { // Check for the default file too big message
        const customMessage = this.dropZone.dictFileTooBig()(file);

        if (customMessage) {
          // Replace the default error message with the custom one
          file.previewElement.querySelector('.dz-error-message span').textContent = customMessage;
        }
      }
    });
  }

  editUploadClickHandler(event) {
    if (!event.target.classList.contains('dz-remove-edit-form')) return false

    event.target.closest('.dz-preview').remove()
  }

  toggleSubmitButton(show = true){
    if(this.dropZone.files.length < 0)
      return

    this.inputTarget.setAttribute("data-files-length", this.dropZone.files.length)

    var message_form = this.element.closest("form");
    if (!message_form) {
      return
    }
    var submit_button_top = message_form.querySelector("#submitBtnTop");
    var submit_button_bottom = message_form.querySelector("#submitBtnBottomContainer");
    var save_draft_button = message_form.querySelector(".save-draft-btn");
    var schedule_button = message_form.querySelector(".schedule-message-btn");

    if (show) {
      if (submit_button_top) {
        submit_button_top.classList.remove("hidden");
      }
      if (submit_button_bottom) {
        submit_button_bottom.classList.remove("hidden");
      }
      if (save_draft_button) {
        save_draft_button.classList.remove("hidden");
      }
      if (schedule_button) {
        schedule_button.classList.remove("hidden");
      }
    } else {
      if (submit_button_top) {
        submit_button_top.classList.add("hidden");
      }
      if (submit_button_bottom) {
        submit_button_bottom.classList.add("hidden");
      }
      if (save_draft_button) {
        save_draft_button.classList.add("hidden");
      }
      if (schedule_button) {
        schedule_button.classList.add("hidden");
      }
    }
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  bindEvents() {
    this.dropZone.on("addedfile", file => {
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start();
      }, 500);
    });

    this.dropZone.on("removedfile", file => {
      file.controller && removeElement(file.controller.hiddenInput);
      this.toggleSubmitButton()
    });

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort();
    });

    this.dropZone.on("success", (file) => {
      this.toggleSubmitButton()
    })

    this.dropZone.on('error', (file) => {
      var element = document.getElementsByClassName('dz-error-message')
      length = element.length
      element[length-1].style.display='block'
      element[length-1].style.opacity='1'
      this.toggleSubmitButton(false)
    });
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 10;
  }

  get maxFileSize() {
    if (this.hasInputTarget && this.inputTarget.dataset.fileInputFrom === 'form') {
      return 50
    }
    return this.data.get("maxFileSize") || 100;
  }

  get dictFileTooBig() {
    return this.data.get("dictFileTooBig") || "File is too big ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB."
  }

  get acceptedFiles() {
    if (this.hasInputTarget && this.inputTarget.dataset.fileInputFrom === 'form') {

      return JSON.parse(this.inputTarget.dataset.acceptedFileType).join(",")
    }
    return this.data.get("acceptedFiles")
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }

  get dictDefaultMessage(){
    if (this.hasInputTarget && this.inputTarget.dataset.fileInputFrom === 'form') {
      return "Drag and drop or click to upload file(s). <br><span class='text-sm'>Maximum 50MB per file.</span>"
    }
    return this.data.get("dictDefaultMessage") || "Drop files here to upload"
  }

  get form() {
    return this.element.closest("form")
  }

}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
        // trigger blur event to check "validation" on upload, blurHandler on validation.js
        this.hiddenInput.dispatchEvent(new Event("blur"))
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false,
    dictFileTooBig: controller.dictFileTooBig,
    dictDefaultMessage: controller.dictDefaultMessage
  });
}
