// `media_recorder` function will record media stream and will start upload using either multipart or direct upload

import MultipartUpload from "../concerns/multipart_upload";
import videojs from "video.js";
import RailsDirectUpload from "../concerns/rails_direct_upload";

export default function media_recorder() {
  var blob;
  var recCompPromiseResolve;
  var timeout_status = 0;
  var dataAvailable;
  // Set `undefined` to window.multiPartUploadInitializer and window.createUploadPromise
  // to make multipart upload work after resetting recorder
  window.createUploadPromise = window.multiPartUploadInitializer = undefined;

  let preview, discard, recording, recordingVideoJs, submitButtonBottom,
      activeTab, recordedBlob, recordedType, browserStream;
  if(this.hasPreviewTarget) {
    preview = this.previewTarget.querySelector("video");
  }

  if(this.hasRecordingTarget) {
    recordingVideoJs = this.recordingTarget.querySelector("video-js");
    recording = this.recordingTarget.querySelector("video");
  }
  let audioThumbnail = this.element.dataset.audioThumbnail;
  let mediaField = this.mediaFieldTarget;
  let downloadButton = this.downloadBtnTarget;
  let isSafari = false;
  let activeTabElement = this.element.querySelector(".toggle-device.active");
  let recordingStartTime = null;
  let recordingTimerSec = undefined;
  let recordingEndTime = null;
  let stopButton = this.stopBtnTarget;
  let resumeButton = this.resumeBtnTargets;
  let pauseButton = this.pauseBtnTargets;
  let recordingIndicator = this.recordingIndicatorTargets;
  let recordingIndicatorIcon = this.recordingIndicatorIconTargets;
  let timer = this.timerTargets;
  if (this.hasSubmitButtonBottomTarget) {
    let submitBtnBottom = this.submitButtonBottomTarget;
  }
  let saveButton;
  let scheduleButton;
  let editRecordingButton;
  if (this.hasSubmitButtonBottomContainer) {
    let submitBtnBottomContainer = this.submitButtonBottomContainerTarget;
  }
  let messageForm = this.messageUITarget
  let recCompPromise;

  // Related to multipart upload
  let usingMultipart = false;
  let canUploadNextParts = false;
  let videoStopped = false;
  let endRecordingTimerStarts = false;
  let chunkIndex = 0;
  let maxMediaSize = 0;
  let recordedMediaSize = 0;
  let partStatus = {};
  let newChunk = [];
  let error_info = [];
  var browserName = this.detectBrowser();
  window.multiPartUploadInitializer = new MultipartUpload(browserName);
  var multipartRetry = 0;
  var alreadyUpdated = false;
  var multipartFailed = false;
  var directUploadInUse = false;
  var discardUpload = false;

  if (this.hasSaveBtnTarget)
    saveButton = this.saveBtnTarget;

  if (this.hasScheduleBtnTarget)
      scheduleButton = this.scheduleBtnTarget;

  if (this.hasEditRecordBtnTarget)
    editRecordingButton = this.editRecordBtnTarget;

  // Listen to discard button click event
  listenDiscardEvent.call(this);

  // set active tab
  setActiveTab();

  // set max media value
  setMaxMedia.call(this);

  // set attribute to PiP element
  setAttributeToPiPElement.call(this);

  // Disable submit bottom button
  disableSubmitBottomBtn.call(this);

  // set browser stream based on media stream that is present
  setBrowserStream();

  setTallVideoValue.call(this);

  let recordingTimeMS = 40000; // 40 seconds

  // --- MEDIA RECORDER FUNCTIONS STARTS ----

  function startRecording(stream, lengthInMS) {
    var options = setMimeType();
    recordedType = options.mimeType;
    let data = [];

    // Initialize media recorder
    let recorder = new MediaRecorder(stream, options);
    window.media_recorder = recorder;

    if(isSafari) {
      timeout_status = setTimeout(function () {
        // In some select Mac devices, such as iMacs, Safari recording crashes.
        // This detection looks out for the likihood of a crash and immediately stops the recording and shows message to user to tell them to use a different browser.
        if (dataAvailable == null) {
          recorder.stop();

          if (window.stream) {
            window.stream.getTracks().forEach(track => {
              track.stop();
            });
          }
          if(window.streamArr) {
            window.streamArr.forEach(stream => {
              stream.getTracks().forEach(track => {
                track.stop();
              });
            })

            window.streamArr = [];
          }

          preview.classList.add("hidden"); // hide the preview stream
          stopButton.classList.add("hidden"); // hide the stop button
          timer.classList.add("hidden"); // hide the timer
          stopButton.classList.remove("recording"); // remove the .recording class from the stop button
          recordingIndicator.classList.add("hidden"); // Hide the recording indicator button
          resumeButton.classList.add("hidden"); // Hide the resume button
          pauseButton.classList.add("hidden"); // Hide the pause button
          document.querySelector(".switch-to-browser-recorder").classList.add("hidden"); // Hide "Switch to recorder" link since that's not supported on this device.

          document.title = 'Recording stopped.  Please switch browsers.';
          document.querySelector(".recording-in-progress-panel").classList.add('hidden');

          document.querySelector(".message-bottom-tab").addEventListener('click', () => {
            submitBtnBottom.disabled = false;
            submitBtnBottom.classList.remove("btn-disabled");
            submitBtnBottom.classList.remove("hidden");
            submitBtnBottomContainer.classList.remove("hidden");
          });

          let uploadErrors = messageForm.querySelector("#upload-errors");

          uploadErrors.classList.remove("hidden");
          if (uploadErrors) {
            uploadErrors.innerHTML = "Recording in Safari isn't supported on this computer.  Try switching to Chrome or Firefox.  Alternatively, you can upload media or post a text message below.";
          }
        }
      }, 17000);
    }

    recorder.onstart = () => {
      // set get current time while starting recording as start time
      recordingTimerSec = recordingStartTime = new Date().getTime();
    }

    if(pauseButton) {
      // listen to pause button and pause recording
      listenPauseButton(recorder);
    }

    if(resumeButton) {
      // listen to resume button and resume recording
      listenResumeButton(recorder);
    }

    recCompPromise = new Promise((resolve, reject) => {
      recCompPromiseResolve = resolve;
    });

    recorder.ondataavailable = event => {
      dataAvailable = true;
      if(event.data.size > 0) {
        // Stop recording if media size limit is over 1 gigabyte
        stopRecordingBasedOnMediaSize.call(this, event, options);
        // Recorded chunk of data will be available here
        convertBlobToChunkAndUpload(event, options, data);
        if(videoStopped){
          recCompPromiseResolve();
        }
      }
    }

    recorder.start(15000); // Every 15 secs a chunk of recording will be available and we can use it in ondataavailable

    log(recorder.state + " for " + (lengthInMS/1000) + " seconds...");

    let clickStopped = new Promise((resolve, reject) => {
      // listen to stop button and stop recording
      listenStopButton(recorder, resolve, reject);
    })

    let stopped = new Promise((resolve, reject) => {
      recorder.onstop = resolve;
      recorder.onerror = event => {
        return reject(event.name);
      }
    });

    return Promise.all([
      clickStopped,
      stopped,
      recCompPromise
    ])
        .then(() => data);
  }

  startRecording.call(this, browserStream, recordingTimeMS).
  then(async recordedChunks => {
    let options;
    let mimeType;

    if(activeTab === "mic" || activeTab === "audio"){
      options = {type: "audio/mp3"};
      mimeType = "mp3";

      // Adjust the playback player for audio-only
      attachThumbnailToAudioMessage();
    } else {
      if(isSafari) {
        options = {type: "video/mp4"};
        mimeType = "mp4";
      }
      else {
        options = {type: "video/webm"};
        mimeType = "webm";
      }
    }

    recordedBlob = new Blob(recordedChunks, options);
    let recordedBlobUrl = URL.createObjectURL(recordedBlob);

    console.log(recordingTimerSec, 'recordingTimerSec value before conversion')
    recordingTimerSec = Math.abs(recordingTimerSec / 1000);
    let video = videojs(recordingVideoJs.id);

    console.log(recordingTimerSec, 'setting duration');
    video.duration = function() {
      return recordingTimerSec;
    }
    video.src({
      src: recordedBlobUrl,
      type: options.type
    });

    recording.src = recordedBlobUrl;
    downloadButton.href = recordedBlobUrl;
    downloadButton.download = "RecordedVideo." + mimeType;

    log("Successfully recorded " + recordedBlob.size + " bytes of " +
        recordedBlob.type + " media.");

    connectionOnline(); // Check if internet connection is present

    // Upload recorded media using either multipart upload or direct upload
    uploadMedia(options, mimeType);

    // Stop media stream
    this.stop();
    this.recordingFinishedUI();

    blob = recordedBlob;
  }).catch(error => log.call(this, error));

  // --- MEDIA RECORDER FUNCTIONS ENDS ----


  // ---HELPER FUNCTIONS STARTS---

  function listenDiscardEvent() {
    // Listen to discard button click event
    if(!(this.hasDiscardBtnTarget === true)) return true;

    discard = this.discardBtnTarget;

    discard.addEventListener("click", () => {
      setTimeout(() => {
        if(discard.dataset.discardRecording === "true") {
          // set multipartFailed to avoid re-upload same part again after discard
          multipartFailed = true;
          discardUpload = true;
        }
      }, 1000);
    })
  }

  function setActiveTab() {
    // set active tab
    if(activeTabElement === undefined || activeTabElement === null) return true;

    activeTab = activeTabElement.getAttribute("data-device");
    if (activeTab === 'mic' || activeTab === 'audio')
      activeTab = 'audio';
  }

  function setMaxMedia() {
    let mediaPercentSize;
    if(this.hasMaxMediaSizeValue && typeof(this.maxMediaSizeValue) === "number" && this.maxMediaSizeValue > 0) {
      // value must be greater than 1 GB
      maxMediaSize = this.maxMediaSizeValue;
    } else {
      // max value will be set as 1 GB
      maxMediaSize = 1073741824; // 1 GB in bytes
    }

    // reduce the max by 10 %(100 MB) this will be a buffer
    mediaPercentSize = maxMediaSize * 0.10;
    maxMediaSize -= mediaPercentSize;
  }

  function setAttributeToPiPElement() {
    // set attribute to PiP element
    if(this.duringRecordPipBtnTarget && window.cameraStream) {
      this.duringRecordPipBtnTarget.setAttribute('recordingStarted', 'true');
      this.duringRecordPipBtnTarget.classList.remove("hidden");
    }
  }

  function disableSubmitBottomBtn() {
    // Disable submit bottom button
    if(this.hasSubmitButtonBottomTarget === undefined || this.hasSubmitButtonBottomTarget === null) return  true

    if (this.hasSubmitButtonBottomTarget) {
      submitButtonBottom = this.submitButtonBottomTarget;
      submitButtonBottom.disabled = true;
      submitButtonBottom.classList.add("btn-disabled");
    }
  }

  function setBrowserStream() {
    // set browser stream based on media stream that is present
    const sUsrAg = navigator.userAgent.toLowerCase();

    if (sUsrAg.indexOf('firefox') > -1) {
      if(window.stream.constructor && window.stream.constructor.name === "CanvasCaptureMediaStream") {
        browserStream = window.stream;
      } else {
        browserStream = preview.mozCaptureStream();
      }
    } else if (sUsrAg.indexOf('safari') !== -1 && sUsrAg.indexOf('chrome') === -1){
      browserStream = window.stream;
      isSafari = true;
    } else {
      if (window.stream) {
        browserStream = window.stream
      } else {
        browserStream = preview.captureStream();
      }
    }
  }

  function setTallVideoValue() {
    // This sets message[tall_video] to 'true' if the aspect ratio is tall when recording via browser (not uploading a file or recording via mobile device, which uses the upload file field)...
    // The code for setting message[tall_video] when uploading video via mobile devise happens in connect() at the top of creation_controller.js.
    if(activeTab !== 'audio') {
      var stream_aspect_ratio = browserStream.getVideoTracks()[0].getSettings().aspectRatio;
      if (stream_aspect_ratio < 1) {
        this.tallVideoFieldTarget.value = true;
      }
    }
  }

  function setMimeType() {
    // set mime type based on the user agent and media stream
    let options;
    isSafari = navigator.vendor.match(/apple/i) &&
        !navigator.userAgent.match(/crios/i) &&
        !navigator.userAgent.match(/fxios/i);
    var sUsrAg = navigator.userAgent.toLowerCase();

    if(isSafari) {
      options = {mimeType: "video/mp4"};
    } else {
      if (MediaRecorder.isTypeSupported("video/webm;codecs=vp8,opus") && !(sUsrAg.indexOf('firefox') > -1)) {
        options = {mimeType: "video/webm;codecs=vp8,opus"};
      } else {
        options = {mimeType: "video/webm"};
      }
    }

    if((activeTab === "mic" || activeTab === "audio") && isSafari) {
      options = {mimeType: "audio/mp4"};
    } else if(activeTab === "mic" || activeTab === "audio") {
      options = { mimeType: 'audio/webm;codecs="opus"' }
    }

    return options;
  }

  function listenPauseButton(recorder) {
    // When Pause button is clicked Pause recording and calculate timer

    function pauseButtonOnClick() {
      clearInterval(window.timerInterval);
      recorder.pause(); // Pause recording
      console.log("recording paused");

      alreadyUpdated = true;
      // Hide/show the pause/resume buttons
      pauseButton.forEach(target => target.classList.add("hidden"));
      if(resumeButton) resumeButton.forEach(target => target.classList.remove("hidden"));

      // Re-color the recording indicator & timer:
      recordingIndicatorIcon.forEach(target => target.classList.remove("bg-primary-600"));
      recordingIndicatorIcon.forEach(target => target.classList.add("bg-gray-500"));

      recordingIndicator.forEach(target => target.querySelector("svg").classList.remove("animate-pulse"));
      timer.forEach(target => target.querySelector("#timer").classList.remove("text-primary-600"));
      timer.forEach(target => target.querySelector("#timer").classList.add("text-gray-500"));

      // calculate timer
      recordingEndTime = new Date().getTime() - recordingStartTime;
      if(recordingTimerSec === recordingStartTime) {
        recordingTimerSec = recordingEndTime;
      } else {
        recordingTimerSec = recordingTimerSec + recordingEndTime;
      }
    }

    pauseButton.forEach(target => target.onclick = pauseButtonOnClick)
  }

  function listenResumeButton(recorder) {
    // When Resume button is clicked Resume recording

    function resumeButtonOnClick() {
      recordingStartTime = new Date().getTime();

      alreadyUpdated = false
      recorder.resume(); // Resume recording
      console.log("recording resume");

      // Hide/show the pause/resume buttons:
      resumeButton.forEach(target => target.classList.add("hidden"));
      if(pauseButton) pauseButton.forEach(target => target.classList.remove("hidden"));

      // Re-color the recording indicator & timer:
      recordingIndicatorIcon.forEach(target => target.classList.add("bg-primary-600"));
      recordingIndicatorIcon.forEach(target => target.classList.remove("bg-gray-500"));

      recordingIndicator.forEach(target => target.querySelector("svg").classList.add("animate-pulse"));
      timer.forEach(target => target.querySelector("#timer").classList.add("text-primary-600"));
      timer.forEach(target => target.querySelector("#timer").classList.remove("text-gray-500"));
    }
    resumeButton.forEach(target => target.onclick = resumeButtonOnClick)
  }

  function stopRecordingBasedOnMediaSize(event, options) {
    let tempChunk = [];
    tempChunk.push(event.data);
    const eventBlob = new Blob(tempChunk, {type: options.mimeType});

    recordedMediaSize += eventBlob.size;

    if((recordedMediaSize > maxMediaSize) && (endRecordingTimerStarts !== true)) {
      endRecordingTimerStarts = true;
      this.endRecordingTimer.call(this, 10, true);
    }
  }

  function convertBlobToChunkAndUpload(event, options, data) {
    // Convert blob to chunk of min 5 MB and upload it to S3

    newChunk.push(event.data); // Push current chunk blob to temp array
    data.push(event.data); // Push current chunk blob to actual recording array
    const newChunkBlob = new Blob(newChunk, {type: options.mimeType})

    const sizeInMb = newChunkBlob.size / Math.pow(1024,2) // Get the size of recorded temp blob

    console.log(`chunk size is ${sizeInMb}`)
    if(sizeInMb >= 5.0 && multipartFailed !== true && (chunkIndex === 0 || partStatus[chunkIndex] === true)) {
      // This condition will pass when recorded temp blob is greater than or equal to 5MB
      // or when multipart upload is still usable
      usingMultipart = true;

      console.log("Using multipart.....")
      // After passing this condition this temp blob will considered as a part
      partStatus[++chunkIndex] = false; // Part upload status will be false

      console.log(`uploading part --- ${chunkIndex}.....`)
      // Upload the NewChunkBlob to S3
      uploadChunkToS3(newChunkBlob, chunkIndex, options.mimeType, videoStopped);

      // reset chunk only if upload is pushed
      newChunk = [];
    }
  }

  function log(msg) {
    console.log(msg);

    if(msg instanceof Error) {
      if(typeof window.createUploadPromise !== undefined) {
        var key_and_upload_id = window.multiPartUploadInitializer.get_key_and_upload_id()
      }

      var additionalInfoHash = {
        browserName: browserName,
        recordingTimerSec: recordingTimerSec,
        key: key_and_upload_id["key"],
        uploadId: key_and_upload_id["uploadId"]
      }

      window.uploadErrorInitializer.assignError(msg, additionalInfoHash);
      window.uploadErrorInitializer.sendError();

      if((window.appsignal !== undefined)) { var span = window.appsignal.createSpan();
        span.setParams({ account_id: accountId }).setError({message: msg});
        window.appsignal.send(span);};
    }
  }

  function listenStopButton(recorder, resolve, reject) {
    // listen to stop button and send resolve for promise
    var recording_indicator_timers = document.getElementsByClassName("recording-indicator-timer")
    for (var timer = 0; timer < recording_indicator_timers.length; timer++) {
      recording_indicator_timers[timer].addEventListener("click", () => {
        console.log('Called from recording-indicator-timer listener');
        updateRecordingTimerValue();

        videoStopped = true;
        recorder.stop();
        return resolve("Stop");
      });
    }
    var stop_recording_buttons = document.getElementsByClassName("stop-recording-button")
    for (var stop_btn = 0; stop_btn < stop_recording_buttons.length; stop_btn++) {
      stop_recording_buttons[stop_btn].addEventListener("click", () => {
        console.log('Called from stop-recording-button listener');
       updateRecordingTimerValue();

       videoStopped = true;
       recorder.stop();
       return resolve("Stop");
     });
    }
  }

  function updateRecordingTimerValue() {
    // calculate the new timer value
    if(alreadyUpdated) return
    alreadyUpdated = true;
    console.log('inside updateRecordingTimerValue method');
    var currentTime = new Date().getTime()
    recordingEndTime = currentTime - recordingStartTime;
    console.log(recordingTimerSec, 'start time', recordingStartTime, 'end time', recordingEndTime, 'currentTime', currentTime)
    console.log(recordingTimerSec === recordingStartTime, 'start and timer sec')
    if(recordingTimerSec === recordingStartTime) {
      recordingTimerSec = recordingEndTime
    } else {
      recordingTimerSec = recordingTimerSec + recordingEndTime;
    }
  }

  function attachThumbnailToAudioMessage() {
    // Add audio thumbnail to video.js player
    recordingVideoJs.classList.remove("vjs-fluid");
    recordingVideoJs.classList.add("vjs-audio");
    if(audioThumbnail) {
      recordingVideoJs.insertAdjacentHTML("beforeend", "<div class='vjs-poster z-10' style='background-image: url("+audioThumbnail+");'></div>");
    }
    recordingVideoJs.querySelector("button.vjs-big-play-button").classList.add("z-100");
    recordingVideoJs.querySelector(".vjs-control-bar").classList.add("z-100");
  }

  function connectionOnline() {
    if(navigator.onLine === false) {
      // If internet connection is offline then show error message and download btn
      let uploadErrors = messageForm.querySelector("#upload-errors");
      let downloadBtn = messageForm.querySelector("#downloadBtn");

      uploadErrors.classList.remove("hidden");
      if (uploadErrors) {
        uploadErrors.innerHTML = "Your connection was interrupted and we couldn't complete the upload. Please download your recording, then manually upload to save your message.";
      }

      if(downloadBtn) {
        downloadBtn.classList.remove("hidden") // show the download button
      }

      // condition to check if internet connection offline
      window.addEventListener('online', () => {
        // Send discard multipart upload trigger with skip page reload when internet is back online
        console.log("connection back online...");

        window.uploadErrorInitializer.setItemInLocalStorage();

        if(multipartFailed === true) {
          window.multiPartUploadInitializer.discardUpload(true);
        }
      })

      return false; // return false if connection is offline
    }

    return true; // return true if connection is online
  }

  function uploadMedia(options, mimeType) {
    if(usingMultipart === true && multipartFailed !== true) {
      discard.classList.remove("hidden");

      if(newChunk.length > 0) {
        const newChunkBlob = new Blob(newChunk, {type: options.type});
        partStatus[++chunkIndex] = false;

        // Upload the NewChunkBlob to S3
        uploadChunkToS3(newChunkBlob, chunkIndex, options.type, videoStopped);
      } else {
        completeMultipartUpload(options.type);
      }

      const noOfParts = Object.keys(partStatus).length;
      window.multiPartUploadInitializer.updatePartProgress(noOfParts);
    } else if (directUploadInUse === false) {
      directUploadRecording(recordedBlob, mimeType, recordingTimerSec);
    }
  }

  // ---HELPER FUNCTIONS ENDS---


  // ---MULTI PART UPLOAD FUNCTIONS STARTS---

  function uploadChunkToS3(blob, index, mimeType, stopped) {
    if(index === 1 && window.createUploadPromise === undefined) {
      // For first part we need to create a create multipart upload trigger and get the upload id from backend
      // Pending Promise will be stored in 'window.createUploadPromise' variable
      window.createUploadPromise = window.multiPartUploadInitializer.createMultipartUpload(mimeType);
      canUploadNextParts = true;
    }

    let error_params = {
      blob: blob,
      index: index,
      mimeType: mimeType,
      stopped: stopped
    }

    if(typeof window.createUploadPromise !== undefined && window.createUploadPromise !== false) {
      // When promise is pending part will wait until promise is returned
      window.createUploadPromise.then(() => {
        // after success response part will be uploaded
        window.multiPartUploadInitializer.upload(blob, index, mimeType).then(data => {
          // If upload is successful then true will be returned in response
          partStatus[chunkIndex] = data; // update the part upload status based on response

          console.log(data, "part:", chunkIndex, "part_status:", partStatus);
          if(data === false) {
            // If the response is false then throw error
            throw "Upload failed";
          }

          if (stopped) {
            // If 'stopped' is true then send complete multipart upload request
            completeMultipartUpload(mimeType);
          }
        }).catch(err => {
          console.log(err);
          handleFailure(error_params, false);

          if(window.appsignal !== undefined) { var span = window.appsignal.createSpan();
            span.setParams({ account_id: accountId, blob: blob, index: index, mimeType: mimeType, stopped: stopped})
            span.setTags({
              tag: "Multipart Upload error"
            }).setError({name: "CreatePartUploadError", message: err});
            window.appsignal.send(span);};
        })
      }).catch(err => {
        console.log(err);

        var additionalInfoHash = {
          browserName: browserName,
          errorName: "CreatePartUploadError"
        }

        // Build error information and send it to backend
        window.uploadErrorInitializer.assignError(err, additionalInfoHash);
        window.uploadErrorInitializer.sendError();

        handleFailure(error_params, false);
        if(window.appsignal !== undefined) { var span = window.appsignal.createSpan();
        span.setParams({ account_id: accountId, blob: blob, index: index, mimeType: mimeType, stopped: stopped})
        span.setTags({
          tag: "Multipart Upload error"
        }).setError({name: "CreatePartUploadError", message: err});
        window.appsignal.send(span);}
      })
    } else {
      multipartFailed = true;
    }
  }

  function completeMultipartUpload(mimeType, incompleteUpload = false) {
    window.multiPartUploadInitializer.completeUpload().then(status => {
      if(status !== false && status !== undefined) {
        if(incompleteUpload) {
          addIncompleteMultipartFields(status)
        } else {
          addMultipartDependentFields(status, mimeType);
        }
      } else {
        throw "Upload complete failed";
      }
      console.log(`response for upload completed`);
    }).catch(err => {
      console.log(err);
      let error_params = { mimeType: mimeType, multipartRetry: ++multipartRetry };

      handleFailure(error_params, true, true);
    })
  }

  function addIncompleteMultipartFields(status) {
    var hiddenFieldKey = document.createElement('input')
    hiddenFieldKey.setAttribute('type', 'hidden');
    hiddenFieldKey.setAttribute("name", "incomplete_multipart_key");
    hiddenFieldKey.setAttribute("id", "media-incomplete-multipart-key");
    hiddenFieldKey.setAttribute('value', status.response.key);

    mediaField.insertAdjacentElement("afterend", hiddenFieldKey);
  }

  function addMultipartDependentFields(status, mimeType) {
    var hiddenFieldKey = document.createElement('input')
    hiddenFieldKey.setAttribute('type', 'hidden');
    hiddenFieldKey.setAttribute("name", "message[key]");
    hiddenFieldKey.setAttribute("id", "media-key");
    hiddenFieldKey.setAttribute('value', status.response.key);

    var hiddenFieldFileName = document.createElement('input')
    hiddenFieldFileName.setAttribute('type', 'hidden');
    hiddenFieldFileName.setAttribute("name", "message[filename]");
    hiddenFieldFileName.setAttribute("id", "media-filename");
    hiddenFieldFileName.setAttribute('value', `recording.${mimeType}`);

    var hiddenFieldChecksum = document.createElement('input')
    hiddenFieldChecksum.setAttribute('type', 'hidden');
    hiddenFieldChecksum.setAttribute("name", "message[checksum]");
    hiddenFieldChecksum.setAttribute("id", "media-checksum");
    hiddenFieldChecksum.setAttribute('value', status.etag);

    var hiddenFieldEndTime = document.createElement('input')
    hiddenFieldEndTime.setAttribute('type', 'hidden');
    hiddenFieldEndTime.setAttribute("name", "message[recorded_duration]");
    hiddenFieldEndTime.setAttribute("id", "media-recording-end-time");
    hiddenFieldEndTime.setAttribute('value', recordingTimerSec);

    var hiddenFieldContentType = document.createElement('input')
    hiddenFieldContentType.setAttribute('type', 'hidden');
    hiddenFieldContentType.setAttribute("name", "message[content_type]");
    hiddenFieldContentType.setAttribute("id", "media-content-type");
    hiddenFieldContentType.setAttribute('value', recordedType);

    if(recordedBlob) {
      var hiddenFieldMediaSize = document.createElement('input')
      hiddenFieldMediaSize.setAttribute('type', 'hidden');
      hiddenFieldMediaSize.setAttribute("name", "message[media_size]");
      hiddenFieldMediaSize.setAttribute("id", "media-size");
      hiddenFieldMediaSize.setAttribute('value', recordedBlob.size);

      mediaField.insertAdjacentElement("afterend", hiddenFieldMediaSize);
    }

    mediaField.insertAdjacentElement("afterend", hiddenFieldEndTime);
    mediaField.insertAdjacentElement("afterend", hiddenFieldContentType);
    mediaField.insertAdjacentElement("afterend", hiddenFieldKey);
    mediaField.insertAdjacentElement("afterend", hiddenFieldFileName);
    mediaField.insertAdjacentElement("afterend", hiddenFieldChecksum);

    // Remove before unload event to allow users to close the browser window
    // window.removeEventListener('beforeunload', this.preventPageRefresh)

    messageForm.querySelector("#progressBar").classList.add("hidden"); // Hide the progress bar in the top controls

    if (messageForm !== null && messageForm.getAttribute('data-message--navigation-changed') === "true") {
      if (messageForm.querySelector("#submitBtnTop")) messageForm.querySelector("#submitBtnTop").classList.remove("hidden");
    }

    // Enable submit button
    if(submitButtonBottom) {
      submitButtonBottom.disabled = false;
      submitButtonBottom.classList.remove("btn-disabled");
      submitButtonBottom.classList.remove("hidden");
      submitBtnBottomContainer.classList.remove("hidden");
      if(saveButton)
        saveButton.classList.remove("hidden");// Reveal the save draft button in the control bar

      if(scheduleButton)
        scheduleButton.classList.remove("hidden");// Reveal the schedule button in the control bar

      if(editRecordingButton)
        editRecordingButton.classList.remove("hidden");// Reveal the edit record button in the control bar
    }

    if (messageForm.querySelector('.more-controls-buttons.new-msg-control-btn')) // Reveal the more menu where thr download link present
      messageForm.querySelector('.more-controls-buttons.new-msg-control-btn').classList.remove('hidden');


    // Close modal only when it is open
    let uploadCloseModal = messageForm.querySelector("#video-upload-close-modal");
    let modalBackground = messageForm.querySelector("#modal-background");
    if(modalBackground) {
      uploadCloseModal.click()
    }
  }

  function handleFailure(params, fromCompleteUpload = false, recurring_request = false) {
    console.log("inside handlingFailure....");
    if(multipartRetry < 3) {
      // Retry multipart upload
      console.log(`multipart retry - ${multipartRetry}.....`)
      ++multipartRetry;

      if(fromCompleteUpload === true) {
        completeMultipartUpload(params.mimeType);
      } else {
        uploadChunkToS3(params.blob, params.index, params.mimeType, params.stopped)
      }

    } else if(videoStopped === true) {
      // This condition will pass when video recording is completed
      // complete the multipart upload that has been completed
      console.log(`recording will complete using rails direct upload.....`)

      usingMultipart = false;
      multipartFailed = true;

      if (directUploadInUse === false) {
        console.log("Called when video recording is ended and multipart upload failed")
        // Use rails direct upload instead of multipart upload to upload recording
        directUploadRecording(recordedBlob, params.mimeType, recordingEndTime);
      }

      if(window.appsignal !== undefined) {
        var span = window.appsignal.createSpan();

        span.setTags({ tag: "Upload error" }).setError(new Error(`Called when video recording is ended and multipart upload failed - request from ${accountId}`));

        window.appsignal.send(span);
      }

      if(recurring_request === false)
        completeMultipartUpload(params.mimeType, true)
    } else {
      // After multiple failure mark Multipart upload as failed
      // complete the multipart upload that has been completed
      console.log("Fallback to rails direct upload logic")

      usingMultipart = false;
      multipartFailed = true;

      if(navigator.onLine === false) {
        let error_info = [];
        error_info.push(browserName, 'UserOffline');
        localStorage.setItem("error-info", JSON.stringify(error_info));
      }

      if(window.appsignal !== undefined) {
        var span = window.appsignal.createSpan();

        span.setTags({ tag: "Upload error" }).setError(new Error(`Fallback to rails direct upload logic - request from ${accountId}`));

        window.appsignal.send(span);
      }

      if(recurring_request === false)
        completeMultipartUpload(params.mimeType, true)
    }
  }

  // ---MULTI PART UPLOAD FUNCTIONS ENDS---


  // ---DIRECT UPLOAD FUNCTIONS STARTS---

  function directUploadRecording(recordedBlob, mimeType, recordingEndTime){
    if(videoStopped === true && discardUpload !== true) {
      directUploadInUse = true;
      new RailsDirectUpload(blobToFile(
        recordedBlob, "recording", mimeType),
        mediaField.dataset.directUploadUrl,
        mediaField,
        submitButtonBottom,
        recordingEndTime,
        browserName,
        saveButton,
        scheduleButton,
        editRecordingButton,
        messageForm).upload();
    }
  }

  function blobToFile(theBlob, fileName, mimeType){
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = `${fileName}.${mimeType}`;
    return theBlob;
  }

  // ---DIRECT UPLOAD FUNCTIONS ENDS---

}