import { Controller } from "stimulus"
import { Emojis } from "src/emojis"

export default class extends Controller {
  static targets = [ "togglePicker",
                     "emojisPicker",
                     "emojisList",
                     "input",
                     "submitButton",
                     "favoriteEmojis" ]

  connect() {
  }

  openPicker(event) {
    this.emojisPickerTarget.classList.remove("hidden");
    this.populatePicker();
  }

  closePicker(event) {
    this.emojisPickerTarget.classList.add("hidden");
    this.emojisListTarget.innerHTML = "";
  }

  clickOutsideClosePicker(event) {
    var toggle_picker = event.target.closest(".toggle-picker");
    if ((this.emojisPickerTarget.contains(event.target) === false) && !toggle_picker) {
      this.emojisPickerTarget.classList.add("hidden");
      this.emojisListTarget.innerHTML = "";
    }
  }

  escClosePicker(event) {
    if (event.key == "Escape") {
      this.emojisPickerTarget.classList.add("hidden");
      this.emojisListTarget.innerHTML = "";
    }
  }

  create(event) {
    var emoji_element = event.target;
    var emoji = emoji_element.dataset.emoji;
    this.inputTarget.value = emoji;
    this.submitButtonTarget.click();

    // Update favorite emojis in localstorage
    var favs_array = [];
    var anonymous_emojis = []
    favs_array.push(`${emoji}`);
    var current_favs_array = JSON.parse(localStorage.getItem("favorite_emojis"));
    if (current_favs_array) {
      if (current_favs_array.includes(`${emoji}`)) {
        var favs_array = current_favs_array;
      } else {
        var combined_array = current_favs_array.concat(favs_array);
        var favs_array = combined_array;
      }
    }
    favs_array.sort(function(x,y){ return x == emoji ? -1 : y == emoji ? 1 : 0; });
    var favs_string = JSON.stringify(favs_array);
    localStorage.setItem("favorite_emojis", favs_string);
  }

  populatePicker() {
    this.emojisListTarget.innerHTML = ''
    var all_emojis_container = this.emojisListTarget;
    const emojis = Emojis.emojis;
    if (!emojis.find((emoji) => emoji.emoji === "🦄")) {
      emojis.push({
        "emoji": "🦄",
        "description": "unicorn",
        "aliases": ["unicorn"],
        "tags": ["mythical", "animal"],
        "unicode_version": "11.0",
        "ios_version": "12.1"
      });
    }

    // Populate elements for all emojis
    emojis.forEach((emoji) => {
      var el = document.createElement('div');
      el.classList.add("emoji","flex","flex-col","items-center","justify-center","w-8","h-8","mx-0.5","text-2xl","cursor-pointer","rounded-lg","hover:bg-gray-100");
      el.dataset.emoji = emoji.emoji;
      el.dataset.description = emoji.description;
      el.dataset.aliases = emoji.aliases;
      el.dataset.tags = emoji.tags;
      el.innerHTML = emoji.emoji;
      el.dataset.action = "click->reactions#create";
      all_emojis_container.appendChild(el);
    });

    this.favoriteEmojisTarget.innerHTML = this.favoriteEmojisTarget.querySelector('.clear-favorites-icon').outerHTML;

    // If favorites are present in localstorage, populate those
    var fav_emojis = localStorage.getItem("favorite_emojis");
    if (fav_emojis) {
      var favorites_container = this.favoriteEmojisTarget;

      // show the favorites container
      favorites_container.classList.remove("hidden");

      var fav_emojis_array = JSON.parse(fav_emojis);
      fav_emojis_array.forEach((emoji) => {
        var emoji_el = all_emojis_container.querySelector("div[data-emoji='"+emoji+"']");
        if (emoji_el) {
        var cloned_emoji_el = emoji_el.cloneNode(true);
        favorites_container.insertAdjacentElement('afterbegin', cloned_emoji_el);
        } else {
          // remove the emoji (which we used to support but don't anymore) from the set of favorites in localstorage, since we've removed it from our main list of available emojis
          var fav_emojis_array = JSON.parse(fav_emojis);
          var index = fav_emojis_array.indexOf(emoji);
          if (index > -1) {
            fav_emojis_array.splice(index, 1);
          }
          localStorage.setItem("favorite_emojis", JSON.stringify(fav_emojis_array));
        }
      });
    }
  }

  createReaction(event) {
    var emoji = event.target.dataset.emoji
    var messageId = event.target.dataset.messageId

    if (event.target.dataset.method === 'delete') {
      var emoji_array = JSON.parse(localStorage.getItem(messageId))
      if (emoji_array === null || emoji_array === undefined || !emoji_array.includes(`${emoji}`)) {
        event.preventDefault();

        console.log('hghj')
        var params = new URLSearchParams({
          user_id: null,
          emoji: emoji,
          from_js: true
        })

        fetch("/messages/" + messageId + `/reactions?${params}`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/js'
          }
        }).then(function (result) {
          result.text().then(function (val) {
            return eval(val);
          });
        });
      }
    }
  }

  clearFavorites() {
    localStorage.removeItem("favorite_emojis");
    this.favoriteEmojisTarget.classList.add("hidden");
  }
}
